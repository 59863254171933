import React, { useState, useEffect, useRef } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Home = ({ langData }) => {



  const [showResults, setShowResults] = useState(false)
  const [proteinResults, setProteinResults] = useState("")

  const ref = useRef(null);
  const sequenceInputHandler = (event) => {

    var currentValue = ref.current.value;

    setProteinResults(currentValue)

    if (currentValue !== "") {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  };

  const smallExplanation = "Input a protein sequence in the box below in a FASTA format. \n Hit the submit button to get the probability of it being toxic."

  return (
    <div className="home-page">
      {(typeof langData === "undefined") ? (
        <p>Loading....</p>
      ) : (<p>{JSON.stringify(langData)}</p>)}
      <div className="input-group">
        <Container fluid>
          <Row>
            <Col>
              <div>
                <h2 className="home-page-title">Welcome to ToxinClass!</h2>
                <h6>A little bit about the tool:</h6>
                <p className="multiline">
                  {smallExplanation}
                </p>
              </div>
            </Col>
            {/* <Col>
              Find out more about the Machine Learning Model!
            </Col> */}

          </Row>
          <Row className="input-area">
            <Col>
              <textarea
                id="proteinInputTextAreaId"
                name="proteinInputTextAreaName"
                className="protein-input-text-area"
                ref={ref}
                placeholder="Input Protein sequence here..."
                style={{ height: "50%", width: "60%" }}
                rows="8"
              />
            </Col>
          </Row>
          <Row className="submit-area">
            <Col>
              <Button onClick={sequenceInputHandler} className="submit-button">Submit</Button>
            </Col>
          </Row>
          {showResults ?
            (<Row className="result-area">
              <div>
                <h4>Results:</h4>
                <p>Probability of toxicity: {proteinResults} %</p>
              </div>
            </Row>)
            : null
          }
        </Container>
      </div>
    </div >
  );
}

export default Home;
