import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './components/Home.js';
import About from './components/About.js';
import NavBar from './components/NavBar.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {

  const [data, setData] = useState([{}])

  useEffect(() => {
    fetch("/languages").then(
      res => {
        res.json()
      }).then(
        data => {
          setData(data)
        }
      )
  }, [])

  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home data={data} />} />
            <Route path="/about" element={<About herdData={data} />} />
            {/* <Route path="/research" element={<Research herdData={data} />} /> */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
