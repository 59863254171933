import React, { useState, useEffect } from 'react';



const About = () => {

  return (
    <div className="about-page">
      <h2>About ToxinClass</h2>
    </div>
  )
}

export default About;
