import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import logo from '../assets/logo.svg'

const NavBar = () => {

  return (
    <Navbar variant="dark">
      <Navbar.Brand href="/">
        <img
          alt=""
          src={logo}
          width="50"
          height="50"
          className="d-inline-block align-top"
        />{' '}
      </Navbar.Brand>
      <Nav className="me-auto">
        <Nav.Link href="/About">About</Nav.Link>
        <Nav.Link href="/Research">Research</Nav.Link>
      </Nav>
    </Navbar >
  );
}

export default NavBar;
